import React, { useEffect } from 'react';

import { FormattedMessage, navigate } from 'gatsby-plugin-intl';

const NotFoundPage = () => {
  useEffect(() => {
    navigate('/');
  });

  return (
    <div>
      <FormattedMessage id="notFound" />
    </div>
  );
};

export default NotFoundPage;
